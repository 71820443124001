import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken, deleteToken } from 'firebase/messaging';


const firebaseConfig = {
    apiKey: "AIzaSyCOFYokhxmqAdJhKl1DmVd6x88M-d0uG2c",
    authDomain: "pwgs-bac77.firebaseapp.com",
    projectId: "pwgs-bac77",
    storageBucket: "pwgs-bac77.appspot.com",
    messagingSenderId: "595263682037",
    appId: "1:595263682037:web:39cdd8550e845d3b1a1cbc"                    
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

  

export { messaging, onMessage, getToken, deleteToken};